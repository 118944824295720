// BottomNavigation.js
import React, { useState } from 'react';
import { TabBar } from 'antd-mobile';
import Icon, { HomeOutlined, FormOutlined, SmileOutlined } from '@ant-design/icons';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Drawer, Typography, Button, Space, Checkbox, Divider } from 'antd';
import { COUNTRIES } from '../../utilities/constants';
import { RefundIcon } from '../../../assets/icons/Icons';
import './style.scss';
const { Text } = Typography;

const BottomNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openCreateRequestModal, setOpenCreateRequestModal] = useState(false);
  const [checkedPolicy, setCheckedPolicy] = useState(false);

  const tabs = [
    {
      key: '/refund-request',
      title: '홈',
      icon: <HomeOutlined />
    },
    {
      key: '/create-refund-request',
      title: '환급신청',
      icon: <FormOutlined />
    },
    {
      key: '/refund',
      title: '환급금',
      icon: <RefundIcon color={location.pathname === '/refund' ? '#3431D6' : '#98A2B3'} />
    },
    {
      key: '/profile',
      title: '내정보',
      icon: <SmileOutlined />
    }
  ];

  return (
    <>
      <TabBar
        activeKey={location.pathname}
        onChange={(key) => {
          if (key === '/create-refund-request') {
            setOpenCreateRequestModal(true);
          } else {
            navigate(key);
          }
        }}
        style={{
          position: 'fixed',
          // zIndex: 9999,
          bottom: 0,
          width: '100%',
          maxWidth: '420px',
          marginLeft: '-1px',
          backgroundColor: '#ffffff',
          padding: '8px 0px',
          border: '1px solid #D0D5DD',
          filter: 'drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.16))',
          height: '60px'
        }}>
        {tabs.map((tab) => (
          <TabBar.Item
            key={tab.key}
            icon={tab.icon}
            title={tab.title}
            style={{
              color: location.pathname === tab.key ? '#3431D6' : '#98A2B3'
            }}
          />
        ))}
      </TabBar>
      {/* Choose Refund country and then navigate to create refund request page*/}
      <Drawer
        title={'환급 신청할 국가를 선택해 주세요'}
        placement={'bottom'}
        onClose={() => setOpenCreateRequestModal(false)}
        open={openCreateRequestModal}
        rootClassName="optiosDrawer"
        height="auto"
        headerStyle={{
          borderBottom: '0px',
          padding: '12px 24px'
        }}
        style={{
          borderRadius: '12px 12px 0px 0px',
          maxHeight: 'calc(100vh - 48px)',
          minHeight: 'calc(70vh - 48px)',
          overflow: 'unset'
        }}>
        <div className="scrollable-content">
          <Text style={{ fontSize: 14, fontWeight: 700 }}>[신청서 작성 시 유의 사항]</Text>
          <ol>
            <li>
              <Text style={{ display: 'block' }}>
                제품구매 시 Billing address는 지정 세금 환급처 주소로 기재해야 하며, Delivery address는 배송 센터로
                기재해야 합니다.
              </Text>
              <Text>
                해당 주소는{' '}
                <Link to="/refund-destination" target="_blank">
                  [환급 및 배송 주소]
                </Link>
                에서 확인 가능합니다.
              </Text>
            </li>
            <li>
              <Text>
                하나의 인보이스에 기재된 상품을 분할해서 배송할 수 없으며, 인보이스상에 확인되는 제품 모두 신청서에
                작성해야 합니다. (내용이 누락될 경우 해당 신청서는 반려 처리가 됩니다.)
              </Text>
            </li>
            <li>
              <Text>인보이스에 기재된 상품 외 다른 상품은 절대 포함시키지 않습니다.</Text>
            </li>
            <li>
              <Text style={{ display: 'block' }}>배송료는 환급 예정 금액에서 차감됩니다.</Text>
              <Text>(상세 내역은 [환급금]에서 확인 가능합니다.)</Text>
            </li>
            <li>
              <Text style={{ display: 'block' }}>
                신청서 검수 시 내용이 적격하지 않을 경우 해당 신청서는 반려 처리되며, 반려 처리된 신청서의 물류는 100%
                반품 처리로 진행됩니다. (반품비용은 따로 청구됩니다.)
              </Text>
            </li>
          </ol>
        </div>

        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          onClick={() => setCheckedPolicy(!checkedPolicy)}>
          <Text style={{ fontSize: 12, fontWeight: 400, color: '#F63D68' }}>
            주의 사항을 전부 확인하였으며, 위 사항에 동의합니다.
          </Text>
          <Checkbox checked={checkedPolicy} onChange={(e) => setCheckedPolicy(e.target.checked)} />
        </div>
        <Divider />
        {Object.values(COUNTRIES)?.map((country, idx) => (
          <Button
            className="w-100 mb-3 p-2"
            style={{ borderRadius: '5px', height: '100%' }}
            hoverable={false}
            key={`country${idx}`}
            onClick={() => {
              setOpenCreateRequestModal(false);
              navigate(`/create-refund-request?country=${country.value}`);
            }}
            disabled={country.disabled || !checkedPolicy}>
            <div className="d-flex justify-content-between w-100">
              <Space>
                <div style={{ lineHeight: '0' }}>{country.icon}</div>
                <Text>{country.label}</Text>
              </Space>
              {country.disabled ? (
                <Text className="pt-1">
                  <strong style={{ color: 'red' }}>서비스 준비중이에요</strong>
                </Text>
              ) : (
                <Text className="pt-1">
                  {`부가세 ${country.vat_rate * 100}% 중`}{' '}
                  <strong style={{ color: 'red' }}>{country.refund_rate * 100 + '%'}</strong> {`환급`}
                </Text>
              )}
            </div>
          </Button>
        ))}
      </Drawer>
    </>
  );
};

export default BottomNavigation;
