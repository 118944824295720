import React from 'react';
import _ from 'lodash';
import { Button, Checkbox, Col, Divider, Form, Image, Input, message, Row, Space, Typography } from 'antd';
import { formatCurrency } from '../../../../utility/utils';
import { COUNTRIES } from '../../../../common/utilities/constants';
import './totalForm.style.scss';
import UploadFileCustom from '../../../../common/components/UploadFile';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const TotalForm = ({ mode = 'edit', form, setHelpInfo, country, handleCoupon }) => {
  const [totalEstRefund, setTotalEstRefund] = React.useState(false);
  const [totalAmount, setTotalAmount] = React.useState(form.getFieldValue('total_amount') || 0);
  const [couponAmount, setCouponAmount] = React.useState(0);
  const [isDisabled, setIsDisabled] = React.useState(true);

  const isFtaDocIssuance = Form.useWatch('fta_doc_issuance', form);
  const isBusinessCustomsDoc = Form.useWatch('business_customs_doc', form);
  const isInspectionPhoto = Form.useWatch('inspection_photo', form);

  // if totalAmount is 0, unable to check the checkbox with useEffect
  React.useEffect(() => {
    if (mode !== 'view' && totalAmount === 0) {
      form.setFieldValue('fta_doc_issuance', false);
      form.setFieldValue('business_customs_doc', false);
      form.setFieldValue('inspection_photo', false);
    }
    form.setFieldValue('total_amount', totalAmount);
  }, [totalAmount]);

  React.useEffect(() => {
    if (isFtaDocIssuance) {
      if (isBusinessCustomsDoc) {
        form.setFieldValue('business_customs_doc', false);
      }
    }
  }, [isFtaDocIssuance]);

  React.useEffect(() => {
    if (isBusinessCustomsDoc) {
      if (isFtaDocIssuance) {
        form.setFieldValue('fta_doc_issuance', false);
      }
    }
  }, [isBusinessCustomsDoc]);

  React.useEffect(() => {
    if (!isBusinessCustomsDoc && !isFtaDocIssuance) {
      setIsDisabled(true);
      return;
    }
    if (isBusinessCustomsDoc || isFtaDocIssuance) {
      setIsDisabled(false);
    }
  }, [isBusinessCustomsDoc, isFtaDocIssuance]);

  // React.useEffect(() => {
  //   if (isBusinessCustomsDoc || isFtaDocIssuance || isInspectionPhoto) {
  //     const serviceFee = getServiceFee(form.getFieldValue);
  //     if (serviceFee > totalAmount) {
  //       message.warning('the service fee is greater than the total refund amount. Please update the product price.');
  //     }
  //   }
  // }, [isBusinessCustomsDoc, isFtaDocIssuance, isInspectionPhoto]);

  React.useEffect(() => {
    getFinalEstRefund(form.getFieldValue);
  }, []);

  const currency = COUNTRIES?.[country]?.currency || COUNTRIES.france.currency;
  const fees = { fta_doc_issuance: 7, inspection_photo: 3, business_customs_doc: 15, basic_inspection: 0 };

  const getTotalEstRefund = (getFieldValue) => {
    const invoices = getFieldValue('invoices') || [];
    let total = 0;
    invoices.forEach((invoice) => {
      const products = invoice?.products || [];
      const totalPod = _.sumBy(products, 'est_refund_amount') || 0;
      total += totalPod;
    });
    setTotalEstRefund(total);
    return total;
  };

  const getServiceFee = (getFieldValue) => {
    const fee = Object.keys(fees).reduce((acc, cur) => {
      let currFee = getFieldValue(cur) ? fees[cur] : 0;
      if (cur === 'inspection_photo') {
        // get total product
        const invoices = getFieldValue('invoices') || [];
        // count all products in invoices
        const totalProduct =
          _.sumBy(invoices, (invoice) => {
            return _.sumBy(invoice.products, 'quantity') || 0;
          }) || 0;
        currFee = currFee * totalProduct;
      }
      return acc + currFee;
    }, 0);
    return fee || 0;
  };

  const getDiscount = (getFieldValue) => {
    const invoices = getFieldValue('invoices') || [];
    const total = _.sumBy(invoices, 'discount') || 0;
    return total;
  };

  const getFinalEstRefund = (getFieldValue) => {
    const estRefund = getTotalEstRefund(getFieldValue);
    const serviceFee = getServiceFee(getFieldValue);
    const discount = getDiscount(getFieldValue);
    const couponAmount = getFieldValue('coupon_amount') || 0;
    setCouponAmount(couponAmount);
    const final = estRefund - serviceFee - discount + couponAmount;
    setTotalAmount(final);
    return final;
  };

  const openAdditionalServiceHelp = () => {
    setHelpInfo({
      show: true,
      title: '부가서비스',
      content: (
        <ul>
          <li>
            제조국을 확인하여 캡처한 후 <strong>구매 인보이스에 업로드</strong>해야 적용됩니다.
            <br />
            (오프라인 구매 건이라면, 상품 내부 택의 제조국을 촬영해서 업로드 해주세요)
          </li>
          <li>
            제품이 EU에서 생산된 경우, 관세 면제를 위한 서류 발급이 가능하며, 이를 위해{' '}
            <strong>원산지 택 사진 또는 구매 사이트에서 확인된 제조국 정보를 등록</strong>해야 합니다.
          </li>
          <li>
            <Text type="danger">제조 국가가 확인되지 않으면 적용이 불가합니다.</Text>
          </li>
        </ul>
      )
    });
  };

  const openCertificateImageHelp = () => {
    setHelpInfo({
      show: true,
      title: '원산지 증명',
      content: (
        <>
          <ul>
            <li>제조국을 확인하여 캡처한 후 <strong>구매 인보이스에 업로드</strong>해야 적용됩니다.<br/>(오프라인 구매 건이라면, 상품 내부 택의 제조국을 촬영해서 업로드 해주세요)</li>
            <li>제품이 EU에서 생산된 경우, 관세 면제를 위한 서류 발급이 가능하며, 이를 위해 <strong>원산지 택 사진 또는 구매 사이트에서 확인된 제조국 정보를 등록</strong>해야 합니다.</li>
            <li><Text type="danger">제조 국가가 확인되지 않으면 적용이 불가합니다.</Text></li>
          </ul>
          <Text strong>예시 이미지</Text>
          <div className="my-3">
            <Image
              style={{ border: '1px #D0D5DD solid', borderRadius: '3px' }}
              src={process.env.PUBLIC_URL + '/cretificate_pictures_help.png'}
            />
          </div>
          <ul>
            <li>제품 원산지가 보이는 페이지를 캡쳐해 주세요</li>
          </ul>
        </>
      )
    });
  };

  return (
    <>
      <div className="py-4 px-3 bg-white mt-3">
        <div className="w-100 d-flex justify-content-between">
          <div className="w-100 d-flex flex-column mt-3">
            <Space className="no-width-space">
              <div style={{ fontSize: '18px', color: '#344054', fontWeight: '700', margin: '20px 0' }}>부가서비스</div>
              {/* <Button onClick={openAdditionalServiceHelp} style={{ border: '0', padding: '0' }}>
                <QuestionCircleOutlined />
              </Button> */}
            </Space>
          </div>
        </div>
        <Row justify="space-between" gutter={[10]}>
          <Col span={23}>
            <b style={{ fontSize: '14px', fontWeight: 700 }}>기본 검수</b>
            <Text
              style={{
                width: '100%',
                display: 'block',
                marginTop: '5px',
                fontSize: '13px',
                fontWeight: 400,
                color: '#667085'
              }}>
              프리티고에서 제공하는 무상 서비스로 색상, 수량, 사이즈 검수가 진행됩니다. 제품의 품질을 확인하는 작업이
              아닌, 주문 건과 해당 제품이 일치하는지 검수합니다.
            </Text>
          </Col>
          <Col span={1} className="d-flex justify-content-end align-items-baseline">
            <Form.Item name="basic_inspection" valuePropName="checked" noStyle shouldUpdate>
              <Checkbox disabled={mode === 'view'} />
            </Form.Item>
          </Col>
        </Row>
        <Divider className="my-2" />
        <Row justify="space-between" gutter={[10]}>
          <Col span={23}>
            <b style={{ fontSize: '14px', fontWeight: 700 }}>검수 사진</b>
            <Text
              style={{
                width: '100%',
                display: 'block',
                marginTop: '5px',
                fontSize: '13px',
                fontWeight: 400,
                color: '#667085'
              }}>
              기본 검수와 제품의 사진을 제공합니다. 이를 확인한 후 제품을 수령하거나 교환할 수 있습니다. 프랑스
              현지에서의 교환 또는 환불을 원하면, <strong>제품당 환 및 환불 수수료 5{currency?.label}</strong>가
              발생합니다.
            </Text>
          </Col>
          <Col span={1} className="d-flex justify-content-end align-items-baseline">
            <Form.Item name="inspection_photo" valuePropName="checked" noStyle shouldUpdate>
              <Checkbox disabled={mode === 'view'} />
            </Form.Item>
          </Col>
        </Row>
        <div className="py-2" style={{ color: '#F63D68', fontWeight: 700 }}>
          + 3{currency?.label}*제품 개수
        </div>
        <Divider className="my-2" />
        <Row justify="space-between" gutter={[10]}>
          <Col span={23}>
            <b style={{ fontSize: '14px', fontWeight: 700 }}>FTA 서류 발급</b>
            <Text
              style={{
                width: '100%',
                display: 'block',
                marginTop: '5px',
                fontSize: '13px',
                fontWeight: 400,
                color: '#667085'
              }}>
              150달러 이상 제품 구매시 필수 요소 입니다.
            </Text>
          </Col>
          <Col span={1} className="d-flex justify-content-end align-items-baseline">
            <Form.Item name="fta_doc_issuance" valuePropName="checked" noStyle shouldUpdate>
              <Checkbox disabled={mode === 'view'} />
            </Form.Item>
          </Col>
        </Row>
        <div className="py-2" style={{ color: '#F63D68', fontWeight: 700 }}>
          + 7{currency?.label}
        </div>
        <Divider className="my-2" />
        <Row justify="space-between" gutter={[10]}>
          <Col span={23}>
            <b style={{ fontSize: '14px', fontWeight: 700 }}>사업자 통관 서류 (FTA 서류 발급 포함)</b>
            <Text
              style={{
                width: '100%',
                display: 'block',
                marginTop: '5px',
                fontSize: '13px',
                fontWeight: 400,
                color: '#667085'
              }}>
              사업자로 통관하는 경우 필수 체크 요소 입니다.
            </Text>
          </Col>
          <Col span={1} className="d-flex justify-content-end align-items-baseline">
            <Form.Item name="business_customs_doc" valuePropName="checked" noStyle shouldUpdate>
              <Checkbox disabled={mode === 'view'} />
            </Form.Item>
          </Col>
        </Row>
        <div className="py-2" style={{ color: '#F63D68', fontWeight: 700 }}>
          + 15{currency?.label}
        </div>
        {/* Upload Invoice picture */}
        <Form.Item
          key={`certificate_pictures`}
          label={
            <div className="w-100 d-flex flex-column">
              <Space className="no-width-space">
                <div className="text-14 gray-900 fw-5">원산지 증명</div>
                <Button onClick={openCertificateImageHelp} style={{ border: '0', padding: '0' }}>
                  <QuestionCircleOutlined />
                </Button>
              </Space>
            </div>
          }
          name={'certificate_pictures'}>
          <>
            <UploadFileCustom
              disabled={isDisabled}
              accept=".png,.jpg,.jpeg,.pdf"
              upload_hint="PDF or JPG or PNG..."
              isViewOnly={mode === 'view' ? true : false}
            />
          </>
        </Form.Item>
      </div>
      <div className="py-4 px-3 bg-white mt-3">
        <div className="w-100 d-flex justify-content-between">
          <div style={{ fontSize: '18px', color: '#344054', fontWeight: '700', margin: '20px 0' }}>쿠폰</div>
        </div>
        <Row gutter={[10]}>
          <Col span={17}>
            <Form.Item key={`coupon_code`} name={'coupon_code'}>
              <Input size="large" disabled={couponAmount > 0} placeholder="쿠폰 코드를 입력해 주세요" />
            </Form.Item>
            <Form.Item key={`coupon_amount`} name={'coupon_amount'} style={{ display: 'none' }}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const couponAmount = getFieldValue('coupon_amount') || 0;
                return (
                  <Button
                    type={couponAmount > 0 ? 'default' : 'primary'}
                    size="large"
                    style={{ width: '100%' }}
                    onClick={() => {
                      if (couponAmount > 0) {
                        form.setFieldsValue({ coupon_code: '' });
                      }
                      handleCoupon();
                    }}>
                    {couponAmount > 0 ? '사용 취소' : '사용'}
                  </Button>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="py-4 px-3 bg-white mt-3 mb-3">
        {/* Total Prices */}
        <Row justify="space-between">
          <Col>환급 예상 금액</Col>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const total = getTotalEstRefund(getFieldValue);
              return formatCurrency(total, 2) + currency.label;
            }}
          </Form.Item>
        </Row>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const couponAmount = getFieldValue('coupon_amount');
            return couponAmount > 0 ? (
              <Row justify="space-between">
                <Col>쿠폰</Col>
                {formatCurrency(couponAmount, 2) + currency.label}
              </Row>
            ) : (
              <></>
            );
          }}
        </Form.Item>
        <Row justify="space-between">
          <Col>부가서비스 금액</Col>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const fee = getServiceFee(getFieldValue);
              return <Text type="danger">-{formatCurrency(fee, 2) + currency.label}</Text>;
            }}
          </Form.Item>
        </Row>
        <Row justify="space-between">
          <Col>할인 금액</Col>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const discount = getDiscount(getFieldValue);
              return <Text type="danger">-{formatCurrency(discount, 2) + currency.label}</Text>;
            }}
          </Form.Item>
        </Row>
        <Row justify="space-between">
          <Text strong>환급 예정 금액</Text>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              let final = getFinalEstRefund(getFieldValue);
              return (
                <Text style={final < 0 ? { color: 'red' } : {}} strong>
                  {formatCurrency(final, 2) + currency.label}
                </Text>
              );
            }}
          </Form.Item>
        </Row>
      </div>
      <Form.Item name="total_amount" noStyle shouldUpdate>
        <Input hidden />
      </Form.Item>
    </>
  );
};

export default TotalForm;
