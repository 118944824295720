import { Button, List, message, Space, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Mail } from 'react-feather';
import { SquiggleLeft, SquiggleLeftBottom, SquiggleRight, SquiggleRightBottom } from '../../../assets/icons/Icons';
import './style.scss';
import { apiClientV2 } from '../../../services/axios';
import { useNavigate } from 'react-router-dom';
import { openBusinessIntroduce } from '../../../redux/reducers/layoutSlice';
import { useDispatch } from 'react-redux';
import SendOnboardingInquiry from './SendOnboardingInquiry';
const { Title } = Typography;

const OnboardingPage = () => {
  const [current, setCurrent] = useState(0);
  const [isOpenSendInquiry, setIsOpenSendInquiry] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const maxSteps = 6;
  const next = () => {
    if (current < maxSteps) {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    if (current > 0) {
      setCurrent(current - 1);
    }
  };

  const updateUser = async (body) => {
    const res = await apiClientV2.put('/user/me', body);
    return res;
  };

  const skip = async () => {
    try {
      const res = await updateUser({
        registered: true
      });
    } catch (error) {
      message.error(error.response?.data?.message || 'Error');
    } finally {
      navigate(`/refund-request`);
    }
  };
  const step5Data = [
    {
      title: '프리티고 홈',
      link: 'https://free-t.co.kr/'
    },
    {
      title: '프리티고 이용 가이드',
      link: 'https://hautevie.notion.site/USER-GUIDE-aece19ac5ec447a3a007c94cb18df772'
    }
  ];
  const stepRange = Array.from({ length: maxSteps }, (_, index) => index);
  function getTitleItem(step) {
    switch (step) {
      case 0:
        return '0. 제품 구매';
      case 1:
        return '1. 신청서 작성';
      case 2:
        return '2. 인보이스 정보 입력';
      case 3:
        return '3. 운송장 번호';
      case 4:
        return '4. 제품 가격 입력';
      case 5:
        return '5. 운송장 확인';
      default:
        return '';
    }
  }
  return (
    <>
      <Content className="onboarding-page">
        {current > 0 && (
          <div className="d-flex onboarding-header">
            <ChevronLeft onClick={prev} />
          </div>
        )}
        <Title level={3} style={{ textAlign: 'center', marginTop: '20px' }}>
          {getTitleItem(current)}
        </Title>
        <div className="onboarding-steps-container">
          <div className="content">
            {current === 0 && (
              <>
                <img
                  src={process.env.PUBLIC_URL + '/onboarding/step0.png'}
                  style={{ width: '100%'}}
                />
                <p style={{ fontSize: '16px', textAlign: 'center', marginTop: '30px' }}>
                  결제 단계에서<strong style={{ color: '#3431D6' }}>택스 환급 주소, </strong>
                </p>
                <p style={{ fontSize: '16px', textAlign: 'center' }}>
                  <strong style={{ color: '#3431D6' }}>배송 대행 주소</strong>를 설정해 주세요
                </p>
              </>
            )}
            {current === 1 && (
              <>
                <img
                  src={process.env.PUBLIC_URL + '/onboarding/step1.png'}
                  style={{ width: '100%'}}
                />
                <p style={{ fontSize: '16px', textAlign: 'center', marginTop: '30px' }}>부가세환급 및 배송 신청서에</p>
                <p style={{ fontSize: '16px', textAlign: 'center' }}>
                  <strong style={{ color: '#3431D6' }}>수취 정보</strong>를 입력해 주세요
                </p>
              </>
            )}
            {current === 2 && (
              <>
                <img
                  src={process.env.PUBLIC_URL + '/onboarding/step2.png'}
                  style={{ width: '100%'}}
                />
                <p style={{ fontSize: '16px', textAlign: 'center', marginTop: '40px' }}>
                  <strong style={{ color: '#3431D6' }}>인보이스 파일을 업로드</strong>하고, 인보이스에 기재 되어있는
                </p>
                <p style={{ fontSize: '16px', textAlign: 'center' }}>
                  <strong style={{ color: '#3431D6' }}>구매 물품을 각각 작성</strong>해 주세요
                </p>
              </>
            )}
            {current === 3 && (
              <>
                <img
                  src={process.env.PUBLIC_URL + '/onboarding/step3.png'}
                  style={{ width: '100%'}}
                />
                <p style={{ fontSize: '16px', textAlign: 'center', marginTop: '40px' }}>
                  <strong style={{ color: '#3431D6' }}>현지 운송장 번호</strong>를 입력해야
                </p>
                <p style={{ fontSize: '16px', textAlign: 'center' }}>
                  <strong style={{ color: '#3431D6' }}>배송대행 물류센터 입고처리</strong>가 가능합니다
                </p>
              </>
            )}
            {current === 4 && (
              <>
                <img
                  src={process.env.PUBLIC_URL + '/onboarding/step4.png'}
                  style={{ width: '100%'}}
                />
                <p style={{ fontSize: '16px', textAlign: 'center', marginTop: '40px' }}>제품 가격을 기재해 주세요</p>
                <p style={{ fontSize: '16px', textAlign: 'center' }}>
                  (<strong style={{ color: '#3431D6' }}>제품단가</strong>와{' '}
                  <strong style={{ color: '#3431D6' }}>VAT</strong>가{' '}
                  <strong style={{ color: '#3431D6' }}>포함된 금액</strong>을 의미합니다)
                </p>
              </>
            )}
            {current === 5 && (
              <>
                <img
                  src={process.env.PUBLIC_URL + '/onboarding/step5.png'}
                  style={{ width: '100%'}}
                />
                <p style={{ fontSize: '16px', textAlign: 'center', marginTop: '40px' }}>
                  운송장 번호가 자동 생성 되며, <strong style={{ color: '#3431D6' }}>신청 내역</strong>에서
                </p>
                <p style={{ fontSize: '16px', textAlign: 'center' }}>
                  <strong style={{ color: '#3431D6' }}>제품 입고 및 출고 내역을 확인</strong>할 수 있습니다
                </p>
              </>
            )}
            {current === 6 && (
              <>
                <div style={{ width: '40vh', marginTop: '60px' }}>
                  <p style={{ fontWeight: '700', fontSize: '20px', textAlign: 'center' }}>
                    추가로 궁금한 사항을 선택해 주세요
                  </p>
                  <List
                    className="onboarding-list"
                    itemLayout="horizontal"
                    dataSource={step5Data}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <a
                              className="d-flex justify-content-between"
                              href={item.link === 'user-guide' ? '#' : item.link}
                              {...(item.link === 'user-guide' ? {} : { target: '_blank' })}
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: 'none'
                              }}
                              onClick={() => {
                                if (item.link === 'user-guide') {
                                  dispatch(openBusinessIntroduce());
                                }
                              }}>
                              <span>{item.title}</span>{' '}
                              <div className="d-flex">
                                {item.count && <b style={{ color: 'red' }}>{item.count}</b>}
                                <ChevronRight />
                              </div>
                            </a>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </div>
                <div style={{ marginTop: '70px' }}>
                  <div style={{ position: 'relative', width: '40vh', textAlign: 'center' }}>
                    <p style={{ fontSize: '16px' }}>
                      더 궁금한 사항이 있으시다면 <strong style={{ textDecoration: 'underline' }}>문의</strong>해 주세요
                    </p>
                    <span style={{ position: 'absolute', right: '50px', bottom: '45px' }}>
                      <SquiggleRight />
                    </span>
                    <Button
                      size="large"
                      style={{
                        marginTop: '70px'
                      }}
                      block
                      className="d-flex justify-content-center"
                      onClick={() => {
                        setIsOpenSendInquiry(true);
                      }}>
                      <div className="me-1">
                        <Mail />
                      </div>
                      문의 하기
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {current === 6 ? (
          <div className="fixed-bottom-container" style={{ bottom: 0 }}>
            <Button
              className="w-100"
              type="primary"
              style={{ backgroundColor: '#3431D6', height: '48px', borderRadius: '4px', maxWidth: '420px' }}
              onClick={skip}>
              시작하기
            </Button>
          </div>
        ) : (
          <div className="d-flex justify-content-between onboarding-steps">
            <span onClick={skip} style={{ cursor: 'pointer', lineHeight: '2' }}>
              건너뛰기
            </span>
            <Space className="steps">
              {stepRange.map((step, idx) => (
                <div key={idx} className={step == current ? 'current' : step < current ? 'active' : 'inactive'}></div>
              ))}
            </Space>
            <Button type="default" onClick={next}>
              다음
            </Button>
          </div>
        )}
      </Content>
      <SendOnboardingInquiry isOpenSendInquiry={isOpenSendInquiry} setIsOpenSendInquiry={setIsOpenSendInquiry} />
    </>
  );
};

export default OnboardingPage;
