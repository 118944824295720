import dayjs from 'dayjs';
import React from 'react';
import StatusTag from '../../../../common/components/StatusTag';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { RightOutlined } from '../../../../assets/icons/Icons';
import { CURRENCIES, INSPECTION_CUSTOMER_STATUS, REFUND_REQUEST_STATUS, RefundRequestChoices } from '../../../../common/utilities/constants';
import UploadFileCustom from '../../../../common/components/UploadFile';
import { formatCurrency } from '../../../../utility/utils';

const { Text } = Typography;

const ProductCard = ({ data, handleOpenRejectModal, handleDeliveryPendingModal, handleInspectionCustomerModal }) => {
  const navigate = useNavigate();
  const currency = CURRENCIES.EUR;

  const handleClickRightIcon = () => {
    navigate(`/refund-request/detail?id=${data?.request_ref || data?.id}`);
  };

  const status = data?.status;

  let isInspectionCustomer = data?.inspection_refund && data?.inspection_customer_status === null;

  return (
    <>
      <Card
        title={<Text>{'신청서 번호'}</Text>}
        extra={
          <div className="d-flex justify-content-between" onClick={handleClickRightIcon}>
            <Text underline style={{ fontWeight: '700' }}>
              {data?.request_ref || data?.id}
            </Text>
            <div className="ms-2">
              <RightOutlined />
            </div>
          </div>
        }
        headStyle={{ padding: '12px', backgroundColor: '#EAECF0' }}
        style={{ backgroundColor: '#F2F4F7' }}>
        <Card.Grid hoverable={false} style={{ width: '100%', padding: '12px', backgroundColor: '#FFF' }}>
          <Space
            direction="horizontal"
            style={{
              width: '100%',
              justifyContent: 'space-between'
            }}>
            <Text>{dayjs(data?.created_at).format('YYYY.MM.DD')}</Text>
            <div className="d-flex justify-content-between" onClick={handleClickRightIcon}>
              <StatusTag status={status} opts={REFUND_REQUEST_STATUS} />
            </div>
          </Space>
        </Card.Grid>
        {data?.invoices?.map((invoice, InvoiceIdx) => {
          return (
            <>
              {invoice?.products?.map((prod, idx) => {
                return (
                  <Card.Grid
                    key={idx}
                    hoverable={false}
                    style={{ width: '100%', padding: '12px', backgroundColor: '#FFF' }}>
                    <Row>
                      <Col flex={4}>
                        <Row style={{ fontWeight: 700 }} gutter={5}>
                          <Col>최종 결제 금액</Col>
                          <Col>
                            {currency.symbol}
                            {prod?.total_price_incl_vat || 0}
                          </Col>
                        </Row>
                        <Row justify="start" style={{ fontWeight: 400, fontSize: '13px', color: '#667085' }}>
                          <Col>{`${prod.quantity}개 ${prod.product_name}`}</Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Grid>
                );
              })}
              {(invoice?.pictures?.length > 0 || data?.bulk_invoice_pictures?.length > 0) && (
                <Card.Grid hoverable={false} className="w-100 p-1" style={{ backgroundColor: '#FFF' }}>
                  <UploadFileCustom
                    value={data?.bulk_invoice_pictures?.length > 0 ? data?.bulk_invoice_pictures : invoice?.pictures}
                    isViewOnly
                  />
                </Card.Grid>
              )}
              <Card.Grid
                hoverable={false}
                style={{
                  width: '100%',
                  padding: '12px',
                  backgroundColor: '#FFF',
                  boxShadow:
                    '1px 0 0 0 #D0D5DD,0 1px 0 0 #F2F4F7,1px 1px 0 0 #D0D5DD,1px 0 0 0 #D0D5DD inset,0 1px 0 0 #D0D5DD inset'
                }}>
                <Row
                  justify="space-between"
                  style={{
                    width: '100%',
                    fontSize: '16px',
                    padding: '7px',
                    fontWeight: 700,
                    color: status === RefundRequestChoices.REFUNDED ? '#4BBF9F ' : '#EAAA08'
                  }}>
                  <Col>{status === RefundRequestChoices.REFUNDED ? '환급 완료' : '환급 예정 금액'}</Col>
                  <Col>
                    {currency.symbol}
                    {formatCurrency(
                      (_.sumBy(invoice?.products, 'est_refund_amount') || 0) - (invoice?.discount || 0),
                      currency.scale
                    )}
                  </Col>
                </Row>
              </Card.Grid>
            </>
          );
        })}
        <Card.Grid
          hoverable={false}
          style={{
            width: '100%',
            padding: '7px',
            marginTop: '10px',
            backgroundColor: '#FFF',
            boxShadow:
              '1px 0 0 0 #D0D5DD,0 1px 0 0 #D0D5DD,1px 1px 0 0 #D0D5DD,1px 0 0 0 #D0D5DD inset,0 1px 0 0 #F2F4F7 inset',
            borderRadius: '3px'
          }}>
          <Row
            justify="space-between"
            style={{
              width: '100%',
              fontSize: '16px',
              padding: '7px',
              fontWeight: 700,
              color: status === RefundRequestChoices.REFUNDED ? '#4BBF9F ' : '#EAAA08'
            }}>
            <Col>{status === RefundRequestChoices.REFUNDED ? '총 환급 완료' : '총 환급 예정 금액'}</Col>
            <Col>
              {currency.symbol}
              {formatCurrency(data?.total_est_refund_amount || 0, currency.scale)}
            </Col>
          </Row>
        </Card.Grid>

        {status === RefundRequestChoices.REJECTED && (
          <Button
            onClick={() => {
              handleOpenRejectModal(data.id, data.reject_reason);
            }}
            block
            danger
            type="primary"
            size="large"
            style={{ borderRadius: '0px 0px 8px 8px' }}>
            반려 사유 확인
          </Button>
        )}
        {isInspectionCustomer && (
          <Row className="w-100">
            <Col span={24} className="w-100">
              <Button
                onClick={() => {
                  handleInspectionCustomerModal(data);
                }}
                block
                type="primary"
                size="large"
                style={{ backgroundColor: '#3431D6', borderRadius: '0px 0px 8px 8px', fontWeight: 700 }}>
                <span style={{ textDecoration: 'underline' }}>검수 사진 확인</span>
              </Button>
            </Col>
          </Row>
        )}

        {data?.invoice_number && !isInspectionCustomer && (
          <Row className="w-100">
            <Col span={[RefundRequestChoices.REQUEST_TAX_REFUND, RefundRequestChoices.WAREHOUSE_IN].includes(data?.status) ? 12 : 24} className="w-100">
              <Button
                onClick={() => {
                  window.open(`https://acieshop.com/pod.php?OrderNo=${data.invoice_number}`, '_blank');
                }}
                block
                type="primary"
                size="large"
                style={{
                  backgroundColor: '#3431D6',
                  borderRadius: [RefundRequestChoices.REQUEST_TAX_REFUND, RefundRequestChoices.WAREHOUSE_IN].includes(data?.status)
                    ? '0px 0px 0px 5px'
                    : '0px 0px 5px 5px'
                }}>
                운송장 번호 조회
              </Button>
            </Col>
            {[RefundRequestChoices.REQUEST_TAX_REFUND, RefundRequestChoices.WAREHOUSE_IN].includes(data?.status) && (
              <Col span={12} className="w-100">
                <Button
                  onClick={() => {
                    handleDeliveryPendingModal(data);
                  }}
                  block
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: data?.delivery_pending ? '#FECDD6' : '#F63D68',
                    borderRadius: '0',
                    color: data?.delivery_pending ? '#000' : '#FFF',
                    borderRadius: '0px 0px 5px 0px'
                  }}>
                  {data?.delivery_pending ? '출고 보류 해제' : '출고 보류'}
                </Button>
              </Col>
            )}
          </Row>
        )}
      </Card>
    </>
  );
};

export default ProductCard;
